<template>
  <pageBack>
<!--    <div class="tips">系统升级中，敬请期待！</div>-->
    <div class="logo"></div>
    <div class="title">请选择注册的账号类型</div>
    <div class="registerButtenBody">
      <div
        class="registerButten"
        @click="ButtActive('0')"
      >
        <i class="el-icon-office-building"></i>
        <div class="registerButtenActive"></div>
        <div class="registerButtenTitle">企业账号</div>
        <div class="registerButtenSmall">企业账号适合多店铺经营的客户</div>
      </div>
      <div
        class="registerButten"
        @click="ButtActive('1')"
      >
        <i class="el-icon-user-solid"></i>
        <div class="registerButtenActive"></div>
        <div class="registerButtenTitle">个人账号</div>
        <div class="registerButtenSmall">个人账号适合独立经营的客户</div>
      </div>
    </div>
  </pageBack>
</template>
<script>
import pageBack from "@/components/basic/pageBack.vue";
import { mapMutations } from "vuex";
export default {
  name: "register",
  data() {
    return {
      active: "-1"
    };
  },
  created() {
    this.init_ERFrom();
    localStorage.removeItem('business')
    localStorage.removeItem('channel')
    if (this.$route.query.business){
      localStorage.setItem("business", this.$route.query.business);
    }
    if (this.$route.query.channel){
      localStorage.setItem("channel", this.$route.query.channel);
    }
  },
  components: { pageBack },
  methods: {
    ...mapMutations(["init_ERFrom"]),
    ButtActive(key) {
      if (this.active == key) {
        this.active = "-1";
        return;
      } else {
        this.active = key;
      }
      this.$nextTick(() => {
        let query = {};
        if (this.$route.query) {
          query = {
            email: this.$route.query.email,
            business: this.$route.query.business,
            channel: this.$route.query.channel
          };
        }
        if (this.active == "0") {
          window.gtag("event", "Click-enterprise-register", {
            event_category: "register",
            event_label: "enterprise"
          });
          this.$router.push({
            path: "/register/enterprise",
            query
          });
        } else {
          window.gtag("event", "Click-personal-register", {
            event_category: "register",
            event_label: "personal"
          });
          this.$router.push({
            path: "/register/personal",
            query
          });
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/common.scss";
.logo {
  width: 166px;
  height: 121px;
  margin: 80px auto 0;
  background-image: url("../../assets/images/main/logo.png");
  background-size: 100% 100%;
}
.tips{
  font-size: 30px;
  text-align: center;
  margin-top: 20px;
}
.title {
  margin: 40px auto 0;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  color: #312f2e;
  line-height: 28px;
}
.registerButtenBody {
  width: 840px;
  margin: 40px auto 0;
  display: flex;
  justify-content: space-between;
  padding-bottom: 83px;
  .registerButten {
    width: 400px;
    height: 226px;
    background: $fff;
    border-radius: 10px;
    border: 2px solid #dcdfe6;
    cursor: pointer;
    padding-top: 60px;
    text-align: center;
    position: relative;
    color:$green !important;
    &.active {
      border: 2px solid #c0c4cc;
      color: #c0c4cc;
      .registerButtenTitle {
        color: #c0c4cc;
      }
      .registerButtenSmall {
        color: #c0c4cc;
      }
      .registerButtenActive {
        display: block;
      }
    }
    &:hover {
      border: 2px solid #c0c4cc;
      color: #c0c4cc;
      .registerButtenTitle {
        color: $green;
      }
      .registerButtenSmall {
        color: $green;
      }
      .registerButtenActive {
        display: block;
      }
    }
    i {
      font-size: 40px;
    }
    .registerButtenActive {
      display: none;
      position: absolute;
      right: 0;
      bottom: -1px;
      width: 72px;
      height: 72px;
      background-image: url("../../assets/images/register/icon01.png");
      background-size: 100% auto;
      background-position: right bottom;
    }
    .registerButtenTitle {
      margin-top: 17px;
      font-size: 20px;
      font-weight: 500;
      //color: #312f2e;
      color:#c0c4cc;
    }
    .registerButtenSmall {
      margin-top: 17px;
      font-size: 18px;
      font-weight: 400;
      //color: #312f2e;
      color:#c0c4cc;
    }
  }
}
</style>
